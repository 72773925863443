import React from "react";
import Text from "@/bit/components/components.text";
import PropTypes from "prop-types";

import {
  getNormalizedSegment,
} from "@gcloud-npm/utils.enums.segments";
import { PRICE_LAYOUT, PRICE_SIZE, PRICE_DECORATION, PRICE_FONT_WEIGHT } from "./enums";

const parsePrice = (
  number,
  decimals,
  decimalChar = ",",
  thousandChar = "."
) => {
  let res = number;
  res = res.toString().replace(",", thousandChar);
  res = parseFloat(res).toFixed(decimals);
  res = res.replace(".", decimalChar);
  const splitNum = res.split(decimalChar);
  splitNum[0] = splitNum[0].replace(/\B(?=(\d{3})+(?!\d))/g, thousandChar);
  if (splitNum[1] !== "00") {
    res = splitNum.join(decimalChar);
  } else {
    res = splitNum[0];
  }
  return res;
};

const normalizePrice = (amount, decimals) => {
  const priceToShow = amount;
  if (!priceToShow && priceToShow !== 0) {
    return null;
  }
  if (!(typeof priceToShow === "number" || typeof priceToShow === "string")) {
    return null;
  }
  return parsePrice(priceToShow, decimals);
};

//layout: compact, default
//size: xl, l, m, s

// Enums

const getMinMaxValues = (min, max) =>
  function (num) {
    return Math.min(Math.max(num, min), max);
  };

// layout -> pendiente
const getFinalSizes = (layout, size) => {
  const sizes = ["xs", "s", "m", "l", "xl", "xxl", "xxxl"];

  const minMax = getMinMaxValues(0, sizes.length - 1);

  const sizeIndex = sizes.indexOf(size);
  const dif = layout === PRICE_LAYOUT.COMPACT ? 4 : 2;

  return {
    currencySize: `price-${sizes[minMax(sizeIndex - dif)]}`,
    quoteSize: `price-${sizes[minMax(sizeIndex - dif)]}`,
    priceSize: `price-${sizes[minMax(sizeIndex)]}`,
  };
};

/**
 * Muestra información sobre el costo de un producto o servicio, generalmente en formato numérico con la moneda correspondiente.
 */
const Price = (props) => {
  const {
    layout,
    size,
    color,
    price,
    currency,
    quote,
    decoration,
    decorationUnity,
    fontType,
    fontTypeUnity,
    segment: segmentProp,
    cyData,
    cms,
    cmsHandler = null,
  } = props;

  if ((!price && !cmsHandler)  || price < 0) {
    return null;
  }

  const priceToShow = normalizePrice(price, 2);

  let finalLayout = PRICE_LAYOUT.DEFAULT;

  if (layout && Object.values(PRICE_LAYOUT).find((item) => item === layout)) {
    finalLayout = layout;
  }

  const segment = getNormalizedSegment(segmentProp);
  let className = `price price-layout--${finalLayout} price-size--${size}`;
  if (cmsHandler) {
    className = `${className} element-editable`;
  }

  const mySizes = getFinalSizes(layout, size);
  const { currencySize, priceSize, quoteSize } = mySizes;

  // cms placeholder
  if (cms && price === "") {
    if (cmsHandler) {
      return (
        <div
          className={`${className} cms-text-placeholder`}
          data-cy={cyData}
          onClick={ () => cmsHandler({ ...props, name: 'price' })}
        >
          <Text
            color="dark_blue"
            text='Price'
          />
        </div>
      );
    } else return null;
  }
  
  return (
    <div
      className={className}
      data-cy={cyData}
      onClick={
        cms && cmsHandler
          ? () => cmsHandler({ ...props, name: 'price' })
          : null
      }
    >
      <Text
        className="price--content"
        text={priceToShow}
        size={priceSize}
        color={color}
        cyData={cyData ? `${cyData}--content` : ""}
        display="inline"
        decoration={decoration}
        fontType={fontType}
        segment={segment}
      />
      <div className="price--unity">
        {currency && (
          <Text
            className="price--currency"
            text={currency}
            size={currencySize}
            color={color}
            cyData={cyData ? `${cyData}--currency` : ""}
            display="inline"
            decoration={decorationUnity || decoration}
            fontType={fontTypeUnity || fontType}
            segment={segment}
          />
        )}
        <div className="price--quote">
          {currency && quote && (
            <Text
              className="price--quote_text"
              text={`/${quote}`}
              cyData={cyData ? `${cyData}--quote_text` : ""}
              size={quoteSize}
              color={color}
              display="inline"
              decoration={decorationUnity || decoration}
              fontType={fontTypeUnity || fontType}
              segment={segment}
            />
          )}
        </div>
      </div>
      <style jsx>{`.price {
  &.price-layout--default {
    .price--unity {
      display: inline;
      padding-left: 2px;
      .price--quote {
        display: inline;
      }
    }
  }
  &.price-layout--compact {
    display: flex;
    align-items: center;
    .price--unity {
      display: inline-grid;
      align-items: center;
      padding-top: 5px;
      padding-left: 2px;

      :global(.text) {
        line-height: 100% !important;
      }
    }
  }
  &-size--xxxl {
    .price--unity {
      align-self: stretch;
    }
    :global(.price--content) {
      font-size: 5rem !important;
      line-height: 4rem;
      align-self: stretch;
    }
  }
  @media screen and (min-width: 426px) {
    &-size--xxxl {
      :global(.price--content) {
        font-size: 6rem !important;
        line-height: 5rem;
      }
    }
  }
  @media screen and (min-width: 768px) {
    &-size--xxxl {
      :global(.price--content) {
        font-size: 7rem !important;
      }
    }
  }
  @media screen and (min-width: 1280px) {
    &-size--xxxl {
      :global(.price--content) {
        font-size: 9rem !important;
        line-height: 7rem;
      }
    }
  }
}
`}</style>
    </div>
  );
};

Price.propTypes = {
  /**
   * {
   *  "info": "Define el valor correspondiente al precio. Puede ser formato string o numérico",
   *  "kind": "both",
   *  "beautifulName": "Price"
   * }
   */
  price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  /**
   * {
   *  "info": "Define el valor correspondiente a la moneda. € es el valor más habitual",
   *  "kind": "both",
   *  "beautifulName": "Currency"
   * }
   */
  currency: PropTypes.string.isRequired,
  /**
   * {
   *  "info": "Define un valor complementario a la moneda, que se representará tras una /. mes es el valor más habitual",
   *  "kind": "both",
   *  "beautifulName": "Quote"
   * }
   */
  quote: PropTypes.string,
  /**
   * {
   *  "info": "Define el tamaño del precio. Puede ser uno de los valores definidos en PRICE_SIZE.",
   *  "kind": "both",
   *  "beautifulName": "Size",
   *  "values": "./enums.js:PRICE_SIZE"
   * }
   */
  size: PropTypes.string,
  /**
   * {
   *  "info": "Define la decoración del precio. Puede ser uno de los valores definidos en PRICE_DECORATION.",
   *  "kind": "both",
   *  "beautifulName": "Decoration",
   *  "values": "./enums.js:PRICE_DECORATION"
   * }
   */
  decoration: PropTypes.string,
  /**
   * {
   *  "info": "Define el color del texto del precio. Debe ser una cadena de texto representando el color.",
   *  "kind": "both",
   *  "beautifulName": "Color"
   * }
   */
  color: PropTypes.string,
  /**
   * {
   *  "info": "Define el tipo de letra de la cantidad",
   *  "kind": "both",
   *  "beautifulName": "Tipo fuente cantidad",
   *  "values": "./enums.js:PRICE_FONT_WEIGHT"
   * }
   */
  fontType: PropTypes.string,
    /**
   * {
   *  "info": "Define el tipo de letra del literal",
   *  "kind": "both",
   *  "beautifulName": "Tipo fuente literal",
   *  "values": "./enums.js:PRICE_FONT_WEIGHT"
   * }
   */
  fontTypeUnity: PropTypes.string,

  /**
   * {
   *  "info": "Define cómo se visualiza el precio y puede ser uno de los siguientes valores: 'default' o 'compact'.",
   *  "kind": "both",
   *  "beautifulName": "Layout",
   *  "values": "./enums.js:PRICE_LAYOUT"
   * }
   */
  layout: PropTypes.string,

  /**
   * {
   *  "info": "Datos de prueba para Cypress",
   *  "kind": "dev",
   *  "beautifulName": "CyData"
   * }
   */
  cyData: PropTypes.string,

  /**
   * {
   *  "info": "Objeto con información sobre las propiedades necesarias (id, timestamp...) para editar los valores en orion.",
   *  "kind": "dev",
   *  "beautifulName": "CMS"
   * }
   */
  cms: PropTypes.object,
  /**
   * {
   *  "info": "Handler para eventos del CMS. Se ejecuta cuando se clicka en el componente desde el CMS.",
   *  "kind": "dev",
   *  "beautifulName": "CMS Handler"
   * }
   */
  cmsHandler: PropTypes.func,
};

Price.defaultProps = {
  price: "",
  quote: "",
  size: PRICE_SIZE.M,
  decoration: PRICE_DECORATION.NONE,
  color: "grey6",
  fontType: PRICE_FONT_WEIGHT.REGULAR,
  cyData: "",
  cms: null,
  cmsHandler: null
};

export { PRICE_LAYOUT, PRICE_SIZE, PRICE_DECORATION, PRICE_FONT_WEIGHT };
export default Price;
